import { useState } from 'react';
import { UUID } from 'crypto';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Spinner } from '@/components/Spinner';
import { Input } from '@/components/Input';
import { InviteButton } from '@/widgets/InvitationModal';
import { usePatientList } from '@/queries/usePatientList';
import { useInvitationList } from '@/queries/useInvitationList';
import { compareByFullName, formatName } from '@/helpers';

interface PatientListItem {
    firstName: string;
    lastName: string;
    email: string;
    id: UUID;
    type: 'invitation' | 'patient';
}

interface Props {
    onSelect: (data?: PatientListItem) => void;
}

export const PatientList = ({ onSelect }: Props) => {
    const { data: patients = [], isLoading: isPatientListLoading } = usePatientList();
    const { data: invitations = [], isLoading: isInvitationListLoading } = useInvitationList();

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedId, setSelectedId] = useState<UUID>();

    const filterBySearchQuery = (item: PatientListItem) => {
        const { firstName, lastName, email } = item;

        return firstName?.match(searchQuery) || lastName?.match(searchQuery) || email?.match(searchQuery)
    };

    const onClick = (item: PatientListItem) => {
        const id = selectedId === item.id ? undefined : item.id;
        const selectedItem = selectedId === item.id ? undefined : item;

        setSelectedId(id);
        onSelect(selectedItem);
    };

    const listItemBaseClasses = "gap-1 px-4 py-2 flex flex-col justify-center cursor-pointer transition-colors rounded";
    const listItemBaseColors = "hover:bg-basic/10";
    const listItemSelectedColors = "bg-basic";

    // this is to not show invitations for existing users as they matched automatically by email
    const patientEmailList = patients.map(({ email }) => email);

    const filteredInvitations = invitations
        .filter(({ used }) => !used)
        .filter(({ email }) => !patientEmailList.includes(email));

    const list = [...patients, ...filteredInvitations].filter(filterBySearchQuery).sort(compareByFullName);

    const isLoading = isPatientListLoading || isInvitationListLoading;

    return (
        <ul className="flex flex-col flex-stretch h-full w-64 gap-4 shrink-0">
            <li className="mt-0.5 mb-3 flex flex items-center gap-2">
                <Input
                    name="search"
                    type="search"
                    placeholder="Search for clients"
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                />

                <InviteButton />
            </li>

            {isLoading && (
                <li className="flex justify-center items-center">
                    <Spinner classList="text-slate-800" />
                </li>
            )}

            {!isLoading && list.map((item, index) => (
                <li
                    key={`${item.id}_${index}`}
                    onClick={() => onClick(item)}
                    className={`${listItemBaseClasses} ${item.id === selectedId ? listItemSelectedColors : listItemBaseColors}`}
                >
                    <div className="capitalize flex gap-2 items-center justify-between">
                        {formatName(item)}
                        {item.type === 'invitation' ? <EnvelopeIcon className="w-4 h-4 text-slate-400" /> : ''}
                    </div>

                    <div className="text-xs text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                        {item.email}
                    </div>
                </li>
            ))}
        </ul>
    )
};
