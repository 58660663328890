import { useState } from 'react';
import { UUID } from 'crypto';
import { PatientList } from '@/widgets/PatientList';
import { InvitationListWidget } from '@/widgets/InvitationList';
import { SurveyListWidget } from '@/widgets/SurveyList';

const Page = () => {
    const [selectedItem, setSelectedItem] = useState<{ id: UUID; email: string; type: 'invitation' | 'patient' }>();

    return (
        <div className="flex gap-4 min-h-full">
            <PatientList onSelect={setSelectedItem} />

            {selectedItem && (
                <div className="pl-4 border-l w-full gap-8 flex flex-col">
                    {selectedItem.type === 'patient' && <SurveyListWidget userId={selectedItem.id} />}
                    {selectedItem.type === 'invitation' && <InvitationListWidget email={selectedItem.email} />}
                </div>
            )}

            {!selectedItem && (
                <div className="pl-4 border-l w-full gap-8 flex flex-col">
                        <div className="sticky top-0 flex flex-col self-start min-h-[90vh] w-full text-sm justify-center items-center">
                            Please select a client from the list on the left to continue.
                        </div>
                </div>
            )}
        </div>
    );
};

export default Page;
